var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-analysis-result__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full capitalize",attrs:{"id":"checkin-table","single-select":"","item-key":"itemKey","headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"item-class":function (item) { return (item.active ? 'v-data-table__selected' : ''); },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler,"item-selected":_vm.itemSelected},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("date-" + (item.checkinId)),attrs:{"disabled":_vm.disabled,"isRequired":true,"label":"dd/mm/yyyy","rules":[_vm.required],"cell-id":item.checkinId,"edit-active":item.active},on:{"edit-row":_vm.toggleEdit},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('DatetimePickerCell',{ref:("startTime-" + (item.checkinId)),attrs:{"edit-active":item.active,"cell-id":item.checkinId,"disabled":_vm.disabled,"label":"00:00 AM","isRequired":true,"type":"time","format":_vm.timeFormat,"rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}})]}},{key:"item.clientIds",fn:function(ref){
var item = ref.item;
return [_c('SelectCell',{ref:("group-" + (item.checkinId)),attrs:{"edit-active":item.active,"cell-id":item.checkinId,"label":"Clients","items":_vm.computedClientNames,"multiple":true,"small-chips":true,"editableComponent":'v-autocomplete',"isRequired":true,"rules":[_vm.required],"default-value":[],"show-condensed":true,"displayValue":_vm.getClientNamesDisplay(item.clientIds, _vm.computedClientNames, 'name')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.clientIds),callback:function ($$v) {_vm.$set(item, "clientIds", $$v)},expression:"item.clientIds"}})]}},{key:"item.staffId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStaffDisplay(item.staffId, _vm.computedUsers, 'name'))+" ")]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center"},[(_vm.isDeletable)?_c('a',{staticClass:"mx-1 bg-red-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":"trash-alt","data-cy":"deleteRecord"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",class:{ 'disabled pointer-events-none': _vm.savingRow },attrs:{"href":"#","disabled":_vm.savingRow},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler(item)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()])]}},(!_vm.disabled && _vm.isAddable)?{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addNewRow}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }