import { format, parseISO } from 'date-fns'

export const getFullName = (userOrClient) => {
    if (
        !userOrClient ||
        (!userOrClient.firstName &&
            !userOrClient.name &&
            !userOrClient?.user?.firstName &&
            !userOrClient?.user?.name)
    ) {
        return ''
    }
    const user = userOrClient?.user ? userOrClient.user : userOrClient
    return user.firstName
        ? `${user.firstName || ''} ${user.lastName || ''}`.trim()
        : user.name
        ? user.name
        : ''
}

export const getFormattedDate = (date) => {
    if (!date) return ''
    return format(parseISO(date), 'dd/MM/yyyy')
}
